import React from "react"
import IconBrain from "./assets/images/icon-brain.inline.svg"
import IconFatburn from "./assets/images/icon-fatburn.inline.svg"
import IconFire from "./assets/images/icon-fire.inline.svg"
import IconHeart from "./assets/images/icon-heart.inline.svg"
import IconHeartbeat from "./assets/images/icon-heartbeat.inline.svg"
import IconLungs from "./assets/images/icon-lungs.inline.svg"
import IconHuman from "./assets/images/icon-human.inline.svg"
import IconHeartMuscle from "./assets/images/icon-heart-muscle.inline.svg"
import IconMolecule from "./assets/images/icon-molecule.inline.svg"
import IconExhausted from "./assets/images/icon-exhausted.inline.svg"
import IconPosture from "./assets/images/icon-posture.inline.svg"
import IconCycling from "./assets/images/icon-cycling.inline.svg"
import IconRunning from "./assets/images/icon-running.inline.svg"
import IconEmoji from "./assets/images/icon-emoji.inline.svg"
import IconLeaderboard from "./assets/images/icon-leaderboard.inline.svg"
import IconList from "./assets/images/icon-list.inline.svg"
import IconMedicalCross from "./assets/images/icon-medical-cross.inline.svg"
import IconSpeedometer from "./assets/images/icon-speedometer.inline.svg"
import IconMuscles from "./assets/images/icon-muscles.inline.svg"
import IconYesNo from "./assets/images/icon-yes-no.inline.svg"
import IconGym from "./assets/images/icon-gym.inline.svg"
import IconHome from "./assets/images/icon-home.inline.svg"
import IconCheck from "./assets/images/icon-check.inline.svg"
import videoPerformance from "./assets/videos/performance.mp4"
import videoPreventDisease from "./assets/videos/prevent-disease.mp4"
import videoWeightLoss from "./assets/videos/weight-loss.mp4"
import videoBrainFunction from "./assets/videos/brain-function.mp4"
import testimonial1 from "./assets/images/testimonial-1.jpg"
import testimonial2 from "./assets/images/testimonial-2.jpg"
import testimonial3 from "./assets/images/testimonial-3.jpg"
import slider1Mobile from "./assets/images/slider1-mobile.jpg"
import slider1 from "./assets/images/slider1.jpg"
import box1 from "./assets/images/box1.jpg"
import box2 from "./assets/images/box2.jpg"
import box3 from "./assets/images/box3.jpg"
import box4 from "./assets/images/box4.jpg"
import autoSlider1 from "./assets/images/slider-1@2x.png"
import autoSlider2 from "./assets/images/slider-2@2x.png"
import autoSlider3 from "./assets/images/slider-3@2x.png"
import autoSlider4 from "./assets/images/slider-4@2x.png"
import autoSlider5 from "./assets/images/slider-5@2x.png"
import imgAetna from "./assets/images/aetna.png"
import imgCigna from "./assets/images/cigna.png"
import imgBb from "./assets/images/bb.png"
import imgMedicare from "./assets/images/medicare.png"
import imgUh from "./assets/images/uh.png"
import imgBrandMit from "./assets/images/brands/mit.png"
import imgBrandUcla from "./assets/images/brands/ucla.png"
import imgBrandRutgers from "./assets/images/brands/rutgers.png"
import imgBrandCiclismo from "./assets/images/brands/ciclismo.png"
import imgBrandAcsm from "./assets/images/brands/acsm.png"
import imgBrandColumbia from "./assets/images/brands/columbia.png"
import imgBrandOura from "./assets/images/brands/oura.png"
import imgBrandWhoop from "./assets/images/brands/whoop.png"
import imgBrandSpartan from "./assets/images/brands/spartan.png"
import imageStep1 from "./assets/images/step-1.png"
import imageStep2 from "./assets/images/step-2.png"
import imageStep3 from "./assets/images/step-3.png"
import imageStep4 from "./assets/images/step-4.png"

export const DEVICES = {
  mobile: 540,
  tablet: 768,
  desktop: 1024,
}

export const MEDIA_QUERIES = {
  belowMobile: `@media (max-width: ${DEVICES.mobile}px)`,
  belowTablet: `@media (max-width: ${DEVICES.tablet}px)`,
  belowDesktop: `@media (max-width: ${DEVICES.desktop}px)`,
}

export const CATEGORIES = [
  {
    name: "All",
  },
  {
    name: "Wellness",
    icon: <IconEmoji />,
    categories: ["Weight Loss", "Heart & Lung Health"],
  },
  {
    name: "Performance",
    icon: <IconSpeedometer />,
    categories: [
      "Weight Loss",
      "Heart & Lung Health",
      "Metabolic & Cellular Health",
      "Breath & Posture",
    ],
  },
  {
    name: "Recovery",
    icon: <IconMedicalCross />,
    categories: ["Breath & Posture"],
  },
  {
    name: "Success Stories",
    icon: <IconLeaderboard />,
    categories: ["Weight Loss", "Heart & Lung Health"],
  },
  {
    name: "Validation & Research",
    icon: <IconList />,
    categories: ["Weight Loss", "Heart & Lung Health"],
  },
  {
    name: "Nutrition",
    categories: ["Heart & Lung Health"],
  },
]

export const BREATHS = [
  {
    name: "Heart Fitness",
    icon: <IconHeart />,
    description:
      "PNOĒ analyzes your heart's condition and whether it poses a limitation to your health or performance by measuring the amount of oxygen your heart pumps into your body in every heart beat.",
    group: 1,
  },
  {
    name: "Lung Fitness",
    icon: <IconLungs />,
    description:
      "PNOĒ analyzes your lungs' condition and whether they pose a limitation to your health or performance by measuring your breathing rate, lung volume and the amount of oxygen your lungs pump into your body every breathing cycle.",
    group: 1,
  },
  {
    name: "Cognition",
    icon: <IconBrain />,
    description:
      "PNOĒ analyzes how your breath affects oxygen delivery to your brain and thus your ability to think at your maximum potential by measuring your breathing rate and carbon dioxide concentration.",
    group: 1,
  },
  {
    name: "Posture",
    icon: <IconPosture />,
    description:
      "PNOĒ analyzes how your breathing affects your posture and risk of lower back injury by measuring how well your inhalations engage your core.",
    group: 1,
  },
  {
    name: "Training Zones",
    icon: <IconRunning />,
    description:
      "PNOĒ analyzes how your body changes between fat and carbohydrate burn in different exercise intensities and provides you with highly personalized training zones as well as the plan to implement them in your training.",
    group: 1,
  },
  {
    name: "Cellular Health",
    icon: <IconFatburn />,
    description: `PNOĒ analyzes how "fit" your cells are by measuring how effective they are in utilizing oxygen and fat as a fuel source.`,
    group: 2,
  },
  {
    name: "Fat/Carb Burn",
    icon: <IconMolecule />,
    description: `PNOĒ analyzes how efficient you are in using fat and carbohydrates as a fuel source at rest and during exercise and pinpoints the exercise intensity where you burn the most amount of fat.`,
    group: 2,
  },
  {
    name: "Metabolism",
    icon: <IconFire />,
    description: `PNOĒ analyzes how fast or slow your metabolism is by measuring whether you are burning more or less calories than the norm at rest and during exercise.`,
    group: 2,
  },
  {
    name: "VO2max - Longevity",
    icon: <IconHeartbeat />,
    description: `PNOĒ analyzes your fitness level and mortality risk by measuring your VO2max, the gold standard in assessing your overall fitness level as well as how long and well you are going to live.`,
    group: 2,
  },
  {
    name: "Cycling Efficiency",
    icon: <IconCycling />,
    description: `PNOĒ measures how much fuel you burn during cycling and helps you become more economical.`,
    group: 2,
  },
]

export const GOALS = [
  {
    value: "performance",
    label: "Athletic Performance",
    video: videoPerformance,
    description:
      "Performance in every sport depends on your how well you utilize O2 and clear CO2. Your lungs, heart, and cells are the systems critical for the flow of O2 and CO2 through your body. PNOĒ pinpoints which system poses the limiting factor in your sport and gets you the most personalized program to overcome it.",
  },
  {
    value: "prevent-disease",
    label: "Disease Prevention",
    video: videoPreventDisease,
    description:
      "The flow of O2 and CO2 involves all systems affected by heart, lung, and metabolic disease. PNOĒ scans these systems with clinical grade accuracy and identifies which one(s) may be at risk of disease.",
  },
  {
    value: "weight-loss",
    label: "Weight Loss",
    video: videoWeightLoss,
    description:
      "A slow metabolism and low fat burning ability are what prevent most from losing weight and keeping it off. By analyzing the flow of O2 and CO2 through your body, PNOĒ tracks how fast your metabolism is, how efficiently you burn fat, and gets you the personalized plan that works for your metabolism.",
  },
  {
    value: "brain-function",
    label: "Improve Cognitive Function",
    video: videoBrainFunction,
    description:
      "Breath is the only biological function affecting your brain that you can actually control. Incorrect breathing, AKA hyperventilation, reduces O2 delivery to your brain and thus your ability to think at the maximum potential. PNOĒ tracks how effective your breathing is and provides the guidance you need to improve it.",
  },
]

export const STATS = [
  {
    icon: <IconFire />,
    name: "Increased metabolism",
    description: "5% increase in daily metabolic rate",
  },
  {
    icon: <IconFatburn />,
    name: "Improved fat burn",
    description: "12% improvement in fat burn efficiency",
  },
  {
    icon: <IconHuman />,
    name: "Age reversing",
    description: "10% reduction in biological age",
  },
  {
    icon: <IconLungs />,
    name: "Improved lung fitness",
    description: `7% increase in lung capability`,
  },
  {
    icon: <IconHeart />,
    name: "Improved heart fitness",
    description: "7% increase in heart fitness",
  },
  {
    icon: <IconHeartbeat />,
    name: "Higher VO2max",
    description: "11% increase in VO2max",
  },
]

export const TESTIMONIALS = [
  {
    image: testimonial1,
    name: "Robert Killian",
    bio: (
      <div>
        World Champion Spartan <br />
        Racing '15 '16
      </div>
    ),
    testimony:
      "PNOĒ helped me take my training to the next level by giving movement specific training recommendations.",
  },
  {
    image: testimonial2,
    name: "Ryan Atkins",
    bio: "Spartan Racing World Champion",
    testimony:
      "PNOĒ found that my lungs were limiting my performance gave me the breathing training I needed to improve.",
  },

  {
    image: testimonial3,
    name: "Lindsay Webster",
    bio: "Spartan Racing World Champion",
    testimony:
      "Overtraining had disturbed and slowed my metabolism. PNOĒ found it and helped me fix it.",
  },
]

export const MAINS = [
  {
    description:
      "Improve performance, prevent disease, lose weight. It starts with a 10-min test at home",
    imageDesktop: slider1,
    imageMobile: slider1Mobile,
    imageAlt: "Man cycling",
  },
]

export const NODES = [
  {
    name: "BF",
    description: "Number of breaths you take every minute",
    dependencies: [],
    group: 1,
  },
  {
    name: "VT",
    description:
      "Volume of air exchanged between your lungs and environment every breathing cycle",
    dependencies: [],
    group: 1,
  },
  {
    name: "FeO2",
    description: "The fraction of oxygen you absorb for the air your inhale",
    dependencies: [],
    group: 1,
  },
  {
    name: "FeCO2",
    description: "The fraction of carbon dioxide in the air you exhale",
    dependencies: [],
    group: 1,
  },
  {
    name: "HR",
    description: "Your heart rate",
    dependencies: [],
    group: 1,
  },
  {
    name: "VE",
    description:
      "Volume of air exchanged between your lungs and environment every minute",
    dependencies: ["VT", "BF"],
    group: 2,
  },
  {
    name: "VCO2",
    description:
      "Volume of carbon dioxide produced by your body every breathing cycle",
    dependencies: ["FeCO2", "VT", "BF"],
    group: 2,
  },
  {
    name: "VO2",
    description: "Volume of oxygen consumed by your body every breathing cycle",
    dependencies: ["FeO2", "VT", "BF"],
    group: 2,
  },
  {
    name: "Fat/Carb",
    description:
      "The contribution of fats and carbohydrates in the calories you burn",
    dependencies: ["VO2", "VCO2"],
    group: 3,
  },
  {
    name: "Kcal",
    description: "The calories you burn",
    dependencies: ["VO2", "VCO2"],
    group: 3,
  },
  {
    name: "O2pulse",
    description:
      "The amount of oxygen your heart pumps into your body per heart beat",
    dependencies: ["HR", "VO2"],
    group: 3,
  },
]

export const THICK_CIRCLES = [
  {
    icon: <IconYesNo />,
    description: "Detailed directions to guide you",
  },
  {
    icon: <IconGym />,
    description: "All gear necessary to conduct the test",
  },
  {
    icon: <IconHome />,
    description: "Pre-paid shipping both ways",
  },
  {
    icon: <IconEmoji />,
    description: "Help via video call if you need it",
  },
  {
    icon: <IconList />,
    description: "Digital and printable results",
  },
  {
    icon: <IconCheck />,
    description: "Expert reviewed results",
  },
]

export const BOX_ITEMS = [
  {
    image: box1,
    name: "PNOĒ metabolic analysis unit",
  },
  {
    image: box2,
    name: "Personal stylized masks",
  },
  {
    image: box3,
    name: "Medical grade antibacterial/viral filter",
  },
  {
    image: box4,
    name: "HR monitor",
  },
]

export const SLIDER_PAGES = [
  {
    name: `Training, Nutrition, and Recovery tracked in one app`,
    description: `PNOĒ AI uses your metabolic profile measured by the PNOĒ test to personalize your training, nutrition, and recovery tracking.`,
    image: autoSlider1,
  },
  {
    name: `Workout plan targeting your weaknesses`,
    description: `PNOĒ AI recommends the optimal combination of resistance, cardio, and HIIT training based on the weaknesses and personalized zones the PNOĒ test identified.`,
    image: autoSlider2,
  },
  {
    name: `Nutrition plan personalized to your metabolism`,
    description: `PNOĒ AI personalizes your nutrition according to your metabolism and training and automatically adjusts your meals down to the serving level.`,
    image: autoSlider3,
  },
  {
    name: `Training & race fueling based on your metabolism`,
    description: `Your metabolic profile syncs automatically and is used to accurately measure the fats and carbs you burn in every workout and recommend the optimal workout nutrition.`,
    image: autoSlider4,
  },
  {
    name: `24/7 health tracking`,
    description: `PNOĒ AI uses your nutrition, activity, and lifestyle data to accurately track how performance metrics like your VO2max, fatburning efficiency, and thresholds are changing over time.`,
    image: autoSlider5,
  },
]

export const BRANDS = [
  {
    alt: "mit",
    src: imgBrandMit,
  },
  {
    alt: "ucla",
    src: imgBrandUcla,
  },
  {
    alt: "rutgers",
    src: imgBrandRutgers,
  },
  {
    alt: "acsm",
    src: imgBrandAcsm,
  },
  {
    alt: "ciclismo",
    src: imgBrandCiclismo,
  },
  {
    alt: "columbia",
    src: imgBrandColumbia,
  },
  {
    alt: "oura",
    src: imgBrandOura,
  },
  {
    alt: "whoop",
    src: imgBrandWhoop,
  },
  {
    alt: "spartan",
    src: imgBrandSpartan,
  },
]

export const NETWORK_IMAGES = [
  {
    name: "aetna",
    source: imgAetna,
  },
  {
    name: "cigna",
    source: imgCigna,
  },
  {
    name: "bluecross blueshield",
    source: imgBb,
  },
  {
    name: "medicare",
    source: imgMedicare,
  },
  {
    name: "united healthcare",
    source: imgUh,
  },
]

export const FAQS = [
  {
    q: "How will PNOĒ help improve my athletic performance?",
    a: (
      <p>
        Performance in every sport depends on how well you utilize O2 and clear
        CO2. Your lungs, heart, and cells are the system's critical channels for
        the flow of O2 and CO2 through your body. PNOĒ pinpoints which system
        poses the limiting factor in your sport and creates a personalized
        program to overcome it.
      </p>
    ),
  },
  {
    q: "How will PNOĒ help me lose weight?",
    a: (
      <p>
        Slow metabolism and low fat-burning ability are what prevent most people
        from losing weight and keeping it off long-term. By analyzing the flow
        of O2 and CO2 through your body, PNOĒ tracks how fast your metabolism
        is, how efficiently you burn fat and gets you the personalized plan that
        works for your individual metabolism.
      </p>
    ),
  },

  {
    q: "How will PNOĒ help me improve my overall health?",
    a: (
      <p>
        The flow of O2 and CO2 involves all systems affected by heart, lung, and
        metabolic disease. PNOĒ scans these systems with clinical-grade accuracy
        and identifies which one(s) may be at risk of disease.
      </p>
    ),
  },
  {
    q: "What does the PNOĒ app track? What information does it give me?",
    a: (
      <p>
        Achieving your fitness or health goals requires optimizing your
        nutrition, activity, sleep, and stress levels. Neglecting any of those
        leads to a much slower evolution towards your goal, and in many cases
        you never achieve it. This is why the PNOĒ app tracks your activity,
        recovery, and nutrition helping you optimize your daily lifestyle and
        reach your health and fitness goal faster. It provides daily guidance on
        your nutrition, workout, and other lifestyle choices to help you adopt
        and sustain the fitness program that works for your individual goals and
        lifestyle. More information on how PNOĒ tracks nutrition, activity, and
        recovery can be found here:
      </p>
    ),
  },
  {
    q:
      "What are the benefits of integrating my metabolic profile in the PNOĒ app?",
    a: (
      <p>
        When you complete your PNOĒ test, your metabolic profile is recorded and
        ready to be integrated into the PNOĒ app once downloaded. Wearable
        devices use an estimation of your metabolic profile as the source for
        every fitness insight they provide you. However, usually, these
        estimations can be highly inaccurate since every person's metabolism is
        different. By measuring your metabolic profile in the PNOĒ app your
        wearable's algorithm can be calibrated to provide you with up to 2x
        higher accuracy in calorie counting as well as additional insights such
        as fat/carbohydrate utilization during exercise, 24/7 vital tracking,
        and more.
      </p>
    ),
  },
  {
    q: "How long does it take to perform the PNOĒ test? How hard is it?",
    a: (
      <>
        <p>
          The PNOĒ assessment typically lasts 20 min and includes the resting
          and active metabolic rate test (each one takes about 10 min to
          complete). To perform the assessment you simply need to download the
          PNOĒ app on your smartphone and power on the PNOĒ device, strap on the
          PNOĒ mask and follow the instructions we will send you.
        </p>
        <p>
          Need more help? Our team offers live support to guide you through the
          test at no additional cost.
        </p>
      </>
    ),
  },
  {
    q: "Is any additional equipment required to perform the PNOĒ test?",
    a: (
      <p>
        The resting metabolic rate test only requires laying in a supine
        position for 7-10 minutes so feel free to choose whatever makes you
        comfortable (bed, sofa, yoga, mat, etc). The PNOĒ exercise test can be
        done on a treadmill, stationary bicycle, rower, or any other type of
        cardio device or even simply using bodyweight movements.
      </p>
    ),
  },
  {
    q: "Do I need to prepare for the test?",
    a: (
      <>
        <p>
          To get the most accurate information from the test you need to follow
          the f preparation protocol below. You will need:
        </p>
        <ol>
          <li>Wifi connection</li>
          <li>3x3 space with proper ventilation</li>
          <li>Comfortable clothing</li>
          <li>Mat or bed to lay down for RMR test</li>
          <li>Wet napkins available to moist the HR monitor</li>
          <li>
            Chair or a box at approximately knee level if they will be
            performing the easy version of our "No Equipment" protocol
          </li>
          <li>3 hrs fasting</li>
          <li>No coffee or smoking 3 hrs before the test</li>
          <li>No intense exercise 24 hours prior to the test</li>
        </ol>
      </>
    ),
  },
  {
    q: "How do I return the device?",
    a: (
      <>
        <p>
          You can find detailed instructions on how to package the PNOĒ unit and
          all components at the end of this video:
        </p>
        <p>
          <a
            href="https://drive.google.com/drive/folders/1IqtLF6Q0262sSSeZn5EUZmcw6AON6vmg"
            target="_blank"
            rel="noopener noreferrer"
          >
            Video Link
          </a>
        </p>
      </>
    ),
  },
  {
    q:
      "Are there additional charges in case I keep the device for longer than 48 hours?",
    a: (
      <p>
        There is a $50 charge for every 24-hour period you keep the device past
        the original 48-hour rental period.
      </p>
    ),
  },
  {
    q: "How does PNOĒ ensure the device is sanitized prior to the test?",
    a: (
      <p>
        PNOĒ uses medical-grade solutions to sanitize the headset as well as all
        other parts included in the PNOĒ kit. The kit always includes an
        antiviral and anti-bacterial filter used in hospital settings in order
        to complete filtration of the inhaled air passing through the PNOĒ
        device flow sensor.
      </p>
    ),
  },
  {
    q: "Do I need to clean the device after using it?",
    a: (
      <p>
        No, our specialized technicians are in charge of the sanitation process.
      </p>
    ),
  },
  {
    q: "In which countries is home testing with PNOĒ available?",
    a: <p>PNOĒ home testing is currently available only in USA.</p>,
  },
]

export const STEPS = [
  {
    title: "Order PNOĒ for your clients",
    description:
      "Order the PNOĒ home test kit for your clients. We'll deliver it to their doorstep and take it back once they are done.",
    image: imageStep1,
  },
  {
    title: "Find what's blocking their progress",
    description:
      "Our Ai analyzes their breath and finds the weaknesses keeping their health and performance from improving.",
    image: imageStep2,
  },
  {
    title: "Give them a plan that works",
    description:
      "Review your clients' results with our experts and offer your clients nutrition, training, and recovery plans personalized to their metabolism through the PNOĒ Precision app.",
    image: imageStep3,
  },
  {
    title: "Monitor them like never before",
    description:
      "The PNOĒ app syncs your breath and wearable data to track 24/7 your biological age, heart, lung, metabolic fitness.",
    image: imageStep4,
  },
]

export const WHAT_WE_MEASURE_ITEMS = [
  {
    id: 1,
    name: "Resting Metabolic Rate",
    icon: <IconHuman />,
    description:
      "The energy (i.e., calories) the human body uses to power its vitals (e.g., liver, brain, heart) and stay alive. It accounts for 50-70% of total caloric burn. Measuring it is when crafting a personalized nutrition plan.",
  },
  {
    id: 2,
    name: "Movement Economy",
    icon: <IconRunning />,
    description:
      "This metric indicates how efficient muscles are in converting energy from nutrients into movement. A higher movement economy means that muscles use fewer calories when moving. Measuring it is vital when determining daily caloric needs.",
  },
  {
    id: 3,
    name: "Heart Fitness",
    icon: <IconHeart />,
    description:
      "An indicator of how effectively the heart pumps blood into the body.",
  },
  {
    id: 4,
    name: "Lung Fitness",
    icon: <IconLungs />,
    description:
      "An indicator of how effectively lungs absorb oxygen and transfer it into the bloodstream.",
  },
  {
    id: 5,
    name: "Cellular Fitness",
    icon: <IconMuscles />,
    description:
      "An indicator of how effectively cells absorb oxygen and use it to convert nutrients (e.g., fats, carbs) into energy.",
  },
  {
    id: 6,
    name: "Recovery Capacity",
    icon: <IconExhausted />,
    description:
      "It's the body's ability to recover after a sprint or a bout of physical activity. A well-functioning metabolism leads to faster and more effective recovery.",
  },
  {
    id: 7,
    name: "Fat / Carb Burn",
    icon: <IconFatburn />,
    description:
      "This metric shows how much fat and carbs the human body uses in different exercise intensities. It's a critical piece of information when crafting a personalized nutrition plan.",
  },
  {
    id: 8,
    name: "VO2Max",
    icon: <IconHeartbeat />,
    description:
      "This is the maximum amount of oxygen the human body can absorb. It's one of the strongest predictors of athletic performance and the strongest predictor of how well and long a person will live.",
  },
  {
    id: 9,
    name: "Cognition & Posture",
    icon: <IconFire />,
    description:
      "Breath is the most powerful lever of the brain and posture. Abnormal breathing patterns limit cognitive capacity and are the strongest predictor of mobility problems such as lower back pain.",
  },
  {
    id: 10,
    name: "Training Zones",
    icon: <IconMolecule />,
    description:
      "They are the training intensities expressed in heart rate ranges where the human body responds metabolically differently. Depending on the time spent in each training zone one develops different physiological traits (e.g., endurance, explosiveness, fat-burning ability).",
  },
]

export const BREATH_ANALYSIS_ITEMS = [
  {
    id: 1,
    name: "Cells",
    icon: <IconFatburn />,
    description:
      "The cells' primary function is using oxygen to break down nutrients (e.g., fats, carbohydrates) and release the energy they contain and keep you alive and moving. PNOĒ measures how efficiently they use oxygen and detects problems caused by metabolic disease, impaired mitochondrial function, and lack of metabolic conditioning training.",
  },
  {
    id: 2,
    name: "Fat & Carbs",
    icon: <IconMolecule />,
    description:
      "Cells use oxygen to break down fats and carbohydrates. When broken down, fat and carbohydrate molecules each require a specific amount of oxygen and produce a particular amount of carbon dioxide. By measuring total oxygen consumption and carbon dioxide production, PNOĒ calculates the total amount of carbs and fats the human body burns.",
  },
  {
    id: 3,
    name: "Mind",
    icon: <IconBrain />,
    description:
      "Breathe is the most potent regulator of how much oxygen is delivered to the brain, determining mental capacity. PNOĒ detects irregular breathing patterns affecting brain oxygenation and causing a reduction in mental capacity.",
  },
  {
    id: 4,
    name: "Recovery",
    icon: <IconExhausted />,
    description:
      "The speed with which the body transitions back normal to normal oxygen and carbohydrate balance after a workout is the most effective indicator of how rapidly it recovers. PNOĒ measures it and provides the most reliable gauge of recovery capacity.",
  },
  {
    id: 5,
    name: "Blood Circulation",
    icon: <IconHeartMuscle />,
    description:
      "The blood's primary function is transferring oxygen from the lungs to the cells. PNOĒ measures how efficient this process is and detects problems due to micronutrient insufficiency (e.g., iron) or abnormal breathing patterns.",
  },
  {
    id: 6,
    name: "Calories",
    icon: <IconFire />,
    description:
      "Breaking down fat or carbohydrate molecules requires a specific amount of oxygen and releases a specific number of calories. By measuring your total oxygen consumption, PNOĒ measures the total number of calories the human body burns.",
  },
  {
    id: 7,
    name: "Lungs",
    icon: <IconLungs />,
    description:
      "The lungs' primary function is to absorb oxygen and remove carbon dioxide. PNOĒ measures how effectively they do so by monitoring breathing rate, the volume of air exchanged with the environment, and the amount of oxygen lungs absorb per breathing rate.",
  },
  {
    id: 8,
    name: "Heart",
    icon: <IconHeart />,
    description:
      "The heart's primary function is to supply oxygen-rich blood to the body. PNOĒ measures how much oxygen the heart provides per heartbeat and detects problems caused by heart disease or lack of cardio exercise. ",
  },
]
